define(['app'], (app) => {
  
  const showPasswordToggleComponent = () => {
    const component = {};
    
    const _config = {
      selectors: {
        typePassword: '[type=password]',
        typeCheckbox: '[type=checkbox]',
      },
      attributes: {
        visibleMessage: 'data-visible-message',
        hiddenMessage: 'data-hidden-message',
      },
    };
    
    const _init = (element) => {
      component.element = element;
      component.nameType = document.querySelectorAll(component.config.selectors.typePassword);
      component.messages = {
        visibleMessage: component.element.getAttribute(component.config.attributes.visibleMessage),
        hiddenMessage: component.element.getAttribute(component.config.attributes.hiddenMessage),
      };
      component.bind();
      return component;
    };
    
    const _bind = () => {
      component.pwdCheckbox = component.element.querySelector(component.config.selectors.typeCheckbox);
      component.pwdCheckbox.addEventListener('change', component.changeType);
    };
    
    const _changeType = (e) => {
      const inputType = e.target.checked ? 'text' : 'password';
      
      Array.from(component.nameType).forEach(el => {
        el.type = inputType;
      });
      
      const message = e.target.checked ? component.messages.visibleMessage : component.messages.hiddenMessage;
      app.publish('accessibility/announce', 'assertive', message);
    };
    
    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.changeType = _changeType;
    
    return component;
  };
  
  return showPasswordToggleComponent;
});
